<template>
  <!-- Game Type2 -->
  <div
    :id="'game-' + data.block"
    class="game-type2"
  >
    <v-container class="game__vendor">
      <v-row class="p-sticky top-0 z-index-5 px-14px">
        <v-col
          cols="12"
          class="py-0 px-1 mb-4"
        >
          <!-- TAB -->
          <v-tabs
            v-model="tab"
            class="game__tab"
            height="65"
            background-color="bg"
            color="primary"
          >
            <v-tab
              v-for="(game,index) in navBars"
              :key="game._id"
              class="px-0"
            >
              <div class="item d-flex align-center justify-center flex-column w-h-65">
                <img
                  v-if="navIcon(game)"
                  :src="baseImgUrl+navIcon(game)"
                  height="18"
                  :class="[ index === tab ? 'primary--text' : 'subtitle--text ManufacturerLogo']"
                >
                <Icon
                  v-else
                  :data="icons[game.class]"
                  width="18"
                  height="18"
                  :class="[ index === tab ? 'primary--text' : 'subtitle--text']"
                />
                <span
                  class="caption mt-1"
                  :class="[ index === tab ? 'primary--text' : 'subtitle--text']"
                >{{ game.game_type }}</span>
              </div>
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <!-- content -->
      <!-- start 開啟遊戲清單 如果Tab是遊戲廠商，而且開啟方式是“清單” -->
      <v-row
        v-if="activeTabType==='vendor' && activeTabOpenMethod==='list_page'"
        class="overlay-auto game-content px-14px"
      >
        <!-- 只跑前九個 activeNavbarContent -->
        <v-col
          v-for="(games) in activeNavbarContent[0].games"
          :key="games._id"
          :cols="6"
          class="px-1 pt-0 pb-10 pb-0"
        >
          <div
            class="game-content rounded h-125 cursor-pointer"
            :style="gradientBg"
          >
            <div
              class="rounded overflow-hidden"
              :class="item.status==='0'?'maintenanceBox':''"
            >
              <!-- 維修中 父層要加 :class="item.status==='0'?'maintenanceBox':''"-->
              <maintenance v-if="item.status=='0'" />
              <v-img
                :src=" baseImgUrl + games.game.image"
                height="125"
                class="rounded"
                position="center right"
                :alt="games.localized_name"
                @click="lauchGame(games.game_code, games.status)"
              />
            </div>
            <span class="game-content__title subtitle-2 icon--text">{{ games.localized_name }}</span>
          </div>
        </v-col>
        <v-col
          :cols="activeNavbarContent.length > 9 || activeVendorType.isOdd ? 6 : 12"
          class="px-1 pt-0 pb-10 pb-0 moreBtn"
          @click="lauchGame(activeNavbarContent.game_code,activeNavbarContent[0].hyperlink)"
        >
          <div
            class="game-content rounded h-125 cursor-pointer d-flex justify-center align-center flex-column"
            :style="gradientBg"
          >
            <Icon
              :data="moreIcon"
              width="18"
              height="18"
            /> More
          </div>
        </v-col>
      </v-row>
      <!-- end 開啟遊戲清單 如果Tab是遊戲廠商，而且開啟方式是“清單” -->
      <v-row
        v-else
        class="overlay-auto game-content px-14px"
      >
        <!-- 只跑前九個 activeNavbarContent -->
        <v-col
          v-for="(item, index) in activeNavbarContent.slice(0, 9)"
          :key="item._id"
          :cols="activeNavbarContent.length>1 ? 6 : 12"
          class="px-1 pt-0 pb-10 pb-0"
        >
          <div
            class="game-content rounded h-125 cursor-pointer"
            :style="gradientBg"
          >
            <div
              class="rounded overflow-hidden"
              :class="item.status==='0'?'maintenanceBox':''"
            >
              <!-- 維修中 父層要加 :class="item.status==='0'?'maintenanceBox':''"-->
              <maintenance v-if="item.status=='0'" />
              <v-img
                :src="activeVendorType.isOdd && activeVendorType.stretchVendorIndex === index && item.image_h5_2 ? baseImgUrl + item.image_h5_2 : baseImgUrl + item.image_h5"
                height="125"
                class="rounded"
                position="center right"
                @click="lauchGame(item.game_code, item.hyperlink)"
              />
            </div>
            <span class="game-content__title subtitle-2 icon--text">{{ item.title }}</span>
          </div>
        </v-col>
        <v-col
          v-if="activeNavbarContent.length>1"
          :cols="activeNavbarContent.length > 9 || activeVendorType.isOdd ? 6 : 12"
          class="px-1 pt-0 pb-10 pb-0 moreBtn"
          @click="goVendorPage(activeVendorPageLink)"
        >
          <div
            class="game-content rounded h-125 cursor-pointer d-flex justify-center align-center "
            :style="gradientBg"
            :class="activeNavbarContent.length > 9 || activeVendorType.isOdd ? 'flex-column' : 'flexRow'"
          >
            <Icon
              :data="moreIcon"
              width="18"
              height="18"
            /> More
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import esport from '@/assets/icons/block/game/type2/esport.svg'
import fish from '@/assets/icons/block/game/type2/fish.svg'
import live from '@/assets/icons/block/game/type2/live.svg'
import lottery from '@/assets/icons/block/game/type2/lottery.svg'
import slot from '@/assets/icons/block/game/type2/slot.svg'
import sport from '@/assets/icons/block/game/type2/sport.svg'
import card from '@/assets/icons/block/game/type2/card.svg'
import sabong from '@/assets/icons/block/game/type2/sabong.svg'
import moreIcon from '@/assets/icons/block/game/moreIcon.svg'
import { mapGetters, mapActions, mapState } from 'vuex'
import { goSlotPage } from '../../../../utils/slot'
import hexToRgba from 'hex-to-rgba'
import { resStatus } from '../../../../utils/resUtils'

import maintenance from '@/components/maintenance'
export default {
  components: { maintenance },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    tab: 0,
    moreIcon,
    icons: {
      esport,
      fish,
      live,
      lottery,
      slot,
      sport,
      card,
      sabong,
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'theme', 'gradientBg']),
    ...mapState({
      isLogin: state => state.user.isLogin,
      isLiffMode: state => state.line.liff.status,
    }),

    navBars() {
      return this.data.data.navbar
    },

    /**
     * active 類別中的 vendor
     * @date 2021-09-10
     * @returns {array} 所有 vendor
     */
    activeNavbarContent() {
      return this.data.data.navbar[this.tab].items
    },

    /**
     * active 類別中的 vendor
     * @date 2023-01-08
     * @returns VendorPage的連結
     */

    activeVendorPageLink() {
      return this.data.data.navbar[this.tab]._id
    },

    /**
     * 判斷tab的type
     * @date 2023-01-08
     * @returns tab的type
     */
    activeTabType() {
      return this.data.data.navbar[this.tab].type
    },

    /**
     * 判斷tab的open_method
     * @date 2023-01-08
     * @returns tab的open_method
     */
    activeTabOpenMethod() {
      return this.data.data.navbar[this.tab].open_method
    },

    /**
     * 判斷 active 類別 vendor 狀態
     * @date 2021-09-10
     * @returns {object} {是否為單數, 最未 vendor 索引}
     */
    activeVendorType() {
      const vendorLength = this.activeNavbarContent.length
      const isOdd = vendorLength % 2 !== 0

      return {
        isOdd,
        stretchVendorIndex: vendorLength === 1 ? 0 : vendorLength - 1,
      }
    },

    gameType() {
      return this.data.data.navbar[this.tab].class
    },

    primaryColor() {
      return this.$vuetify.theme.themes[this.theme]?.primary || ''
    },

  },

  methods: {
    ...mapActions([
      'get_game_url',
      'show_alert',
      'set_progress_status',
      'get_game_url_by_device',
    ]),
    /**
     * 判斷現在是亮版或暗版 決定icon
     * @date 2023-01-08
     */
    navIcon(game) {
      return this.theme === 'dark' ? game.nav_icon_dark || '' : game.nav_icon_light || ''
    },
    /**
     * 到 vendor 頁
     * @date 2023-01-08
     * @param {string} vendorID
     */
    goVendorPage(vendorID) {
      this.$router.push({
        name: 'vendor',
        params: {
          id: vendorID,
        },
      })
    },
    /**
     * 啟動遊戲 OR slot 列表頁
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     * @param {string} hyperlink 遊戲代碼
     */
    lauchGame(gameCode, hyperlink) {
      // 如果尚未登入
      if (!this.isLogin) {
        return this.show_alert({
          icon: 'fail',
          text: this.$t('flashMessage.loginFirst'),
        })
      }

      // 如果為 slot fish 爾且不是遊戲清單，就到 slot 頁面
      const goSlotPageItems = ['slot', 'fish']
      if (goSlotPageItems.includes(this.gameType) && this.activeTabType !== 'vendor') return this.goSlotPage(hyperlink)

      console.log(`開啟遊戲 liff mode:${this.isLiffMode}`)

      const ua = navigator.userAgent.toLowerCase()
      console.log(ua)
      console.log('LINE browser: ' + ua.indexOf('line'))

      // 不為 liff 模式下 也不為 line 開啟
      if (!this.isLiffMode || ua.indexOf('line') < 0) {
        if (hyperlink === '0') return false
        // 如果Tab是遊戲廠商，而且開啟方式是“清單”，則另開視窗 游戲
        if (this.activeTabType === 'vendor' && this.activeTabOpenMethod === 'list_page') return this.goBlankPage(gameCode)
        // 如果Tab是遊戲類型，gameType為 live || lottery 類型，則另開視窗 游戲
        if (this.activeTabType !== 'vendor' && (this.gameType === 'live' || this.gameType === 'lottery')) return this.goBlankPage(gameCode)
      }

      // 進入嵌入遊戲頁面
      this.$router.push({
        name: 'launchGame',
        query: {
          t: gameCode,
          redirectUrl: this.$route.path,
        },
      })
    },

    /**
     * 進入遊戲列表頁面
     * @date 2021-07-02
     * @param {string} hyperlink 遊戲代碼
     */
    goSlotPage(hyperlink) {
      goSlotPage(hyperlink)
    },

    /**
     * 另開遊戲視窗 (live)
     * @date 2021-08-04
     * @param {string} gameCode 遊戲代碼
     */
    async goBlankPage(gameCode) {
      this.set_progress_status(true)
      this.getGameUrl(gameCode)
    },

    /**
     * 取得遊戲 url (成功)
     * @date 2021-07-02
     * @param {object} gameUrl 遊戲連結 url
     */
    getGameUrlSuccess(gameUrl, windowObj) {
      this.show_alert({ icon: 'done' })
      this.gameUrl = gameUrl.url
      this.set_progress_status(false)
      windowObj.location.href = gameUrl.url
    },

    /**
     * 取得遊戲 url (失敗)
     * @date 2021-07-02
     */
    getGameUrlFail() {
      this.set_progress_status(false)
      this.show_alert({ icon: 'fail' })
      this.$router.replace({ path: '/' })
    },

    /**
     * 取得遊戲 url
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     */
    async getGameUrl(gameCode) {
      const windowObj = window.open('', '_blank')
      const res = await this.get_game_url_by_device({ gamecode: gameCode, is_mobile: 1 })
      resStatus(res, this.getGameUrlSuccess, this.getGameUrlFail, windowObj)
    },
  },
}
</script>

<style lang="scss" scoped>
.px-14px {
	padding: 0 14px;
}
.w-h-65 {
	width: 65px;
	height: 65px;
}

.h-125 {
	height: 125px;
}

.ManufacturerLogo{
  filter: grayscale(100%);
}
.moreBtn.col-12 .h-125{
  height:36px;
}
.moreBtn.col-12 .h-125.flexRow svg{
  margin-right: 4px;
}
</style>
